/* Container del componente */
.status-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 32px;
  border: 1px solid var(--color-border);
  cursor: pointer;
  width: 100%;
  max-width: 250px; /* Modifica per essere scalabile */
  margin: 20px auto;
  transition: background-color 0.3s ease;
}

/* Punto verde che salta */
.dot {
  margin-left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(5, 203, 5); /* Sempre verde */
  animation: jump 1s ease-in-out infinite; /* Animazione di salto */
}

/* Animazione di salto */
@keyframes jump {
  0% {
    transform: translateY(0); /* Inizia nella posizione originale */
  }
  25% {
    transform: translateY(-10px); /* Salta in alto */
  }
  50% {
    transform: translateY(0); /* Torna alla posizione originale */
  }
  75% {
    transform: translateY(-5px); /* Salta leggermente più in alto */
  }
  100% {
    transform: translateY(0); /* Torna alla posizione originale */
  }
}

/* Testo al centro */
.text {
  flex-grow: 1;
  text-align: center;
  font-size: 0.8rem;
  color: var(--color-muted);
}

/* Icona a destra */
.icon {
  font-size: 0.9rem;
  color: var(--color-muted);
  margin-right: 15px;
  margin-top: 2px;
}

/* Effetto al passaggio del mouse */
.status-indicator:hover {
  background-color: #6161614e;
}
