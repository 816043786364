/* Contenitore principale */
.title-container {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--color-background);
}
.title-title {
  font-size: 5rem;
  margin-top: 5vh;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
}
