/* Contenitore principale */
.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenitore ingrandito con effetto vetro smerigliato */
.enlarged-container {
  width: 100%;
  height: 100vh;
  padding: 0px; /* Rimuove il padding extra */
}

/* Stile per il video */
.animation-video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Mantiene la qualità del video senza distorcerlo */
  transition: all 0.3s ease; /* Transizione morbida */
}

/* Ottimizzazione per l'aspetto responsivo */
@media (max-width: 768px) {
  .enlarged-container {
    max-width: 100%; /* Riduci la larghezza massima per schermi più piccoli */
  }
  .animation-container {
    padding: 10px; /* Meno padding sui dispositivi mobili */
  }
}
