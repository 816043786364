/* Contenitore principale della timeline */
.timeline-container {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  height: auto;
  position: relative;
}

/* Timeline verticale */
.timeline {
  position: relative;
  width: 100%;
}

/* Ogni elemento della timeline */
.timeline-item {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
}

/* Linea che collega i div nella timeline */
.timeline-line {
  position: absolute;
  width: 2px;
  background-color: var(--color-muted);
  top: 0;
  bottom: 0;
  left: 20%;
}

.timeline-number {
  position: absolute;
  top: 45%;
  transform: translateX(-50%);
  font-size: 45px;
  color: blue;
  font-weight: bold;
  background-color: #000000;
}

.timeline-content {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 40px;
  overflow: hidden;
  transition: transform 0.6s ease, box-shadow 0.6s ease;
}

.description {
  display: flex;
  align-items: center;
}

/* La sezione della foto occupa il 30% */
.timeline-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.card p {
  font-size: 30px;
  font-weight: 400;
  /* line-height: 20px; */
  color: #666;
}

.card p.small {
  font-size: 14px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  height: 50px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #0073ff;
  border-radius: 0 4px 0 50px;
}

.go-arrow {
  color: white;
  font-family: courier, sans;
  font-size: 25px;
  padding-left: 20%;
  padding-bottom: 20%;
}

.card1 {
  display: block;
  position: relative;
  max-width: 500px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #0073ff;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(50);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #fff;
}

@media (max-width: 1000px) {
  /* Rimuovi la linea verticale della timeline */
  .timeline-line {
    display: none;
  }
}
