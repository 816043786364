/* Contenitore principale */
.title-photos-container {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Stile del titolo */
.title {
  font-size: 3rem;
  margin-top: 5vh;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-dark);
}

/* Griglia delle foto */
.photos-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding: 50px;
}

/* Riga di foto */
.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Foto rettangolari (sinistra e destra) */
.photo {
  border-radius: 10px;
  overflow: hidden;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Foto sinistra rettangolare */
.photo-rect-left {
  flex: 3; /* 30% */
}

/* Foto destra rettangolare */
.photo-rect-right {
  flex: 4; /* 40% */
}
