/* Home Page */
.home-page {
  opacity: 0;
  transition: opacity 1.5s ease-in;
}

.home-page.show {
  opacity: 1;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* src/pages/HomePage.css */
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;
  color: var(--color-text-dark);
}

/* Resto del codice CSS */
.home-page {
  height: auto;
  background-color: var(--color-background);
}

.home-content {
  margin-top: 30vh;
  margin-bottom: 30vh;
  padding: 50px;
}

.home-page h1 {
  color: var(--color-text);
}

.home-content p {
  font-size: 1.2rem;
}

.home-content button {
  padding: 10px 20px;
  background-color: #008cba;
  color: var(--color-text-dark);
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.home-content button:hover {
  background-color: #005f73;
}

.services {
  height: auto;
  background-color: var(--color-background);
}

.services {
  opacity: 0;
  transition: opacity 1.5s ease-in;
}

.services.show {
  opacity: 1;
}

.timeline-section {
  height: auto;
  background-color: var(--color-background-dark);
}

.gemello-digitale {
  height: 150vh;
  background-color: var(--color-background);
}

.gemello-digitale-content {
  margin-top: 18vh;
}

.services {
  height: auto;
  background-color: var(--color-background);
}

.services-content {
  margin-top: 18vh;
}

.intro-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.intro-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

/* Contenitore principale per entrambi */
.dynamic-text-container,
.header-container {
  position: relative;
  padding: 20px;
  width: 100%; /* Assicuriamo che entrambe abbiano la stessa larghezza */
}

/* Stile corsivo elegante per il testo sopra */
.corsivo-elegante {
  position: absolute;
  top: 40px;
  left: 30px;
  font-family: "Dancing Script", cursive; /* Font corsivo elegante */
  font-size: 2.5rem; /* Dimensione del font */
  color: #555; /* Colore del testo */
  margin: 0;
}

/* Stile per l'h1 */
h1 {
  margin-top: 70px; /* Distanza dal testo sopra */
  font-size: 2.5rem;
  color: #333; /* Colore del titolo */
}

/* Stile per h6 */
h6 {
  margin-top: 20px;
  color: #666;
  font-size: 1.2rem; /* Font più piccolo per h6 */
}

/* Aggiungiamo il padding per il contenuto in modo che non tocchi i bordi */
.dynamic-text-container,
.header-container {
  padding: 20px;
}
