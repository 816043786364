/* Base styles: desktop-first approach */

/* Video container: altezza 100vh e larghezza 100% */
.video-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adatta il video alla dimensione senza deformarlo */
}

/* Dettagli sotto il video */
.details-container {
  padding: 80px;
  text-align: center;
  min-height: 60vh;
}

.details-container h1 {
  color: var(--color-text);
  font-size: 2.5rem;
  margin: 20px 0;
}

.details-container p {
  font-size: 1.2rem;
  margin: 10px 0 20px 0;
  line-height: 1.6;
  color: #555;
}

.details-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.case-details-page {
  background-color: var(--color-background);
}

/* Media Queries */

/* Per schermi medi (tablet, 768px) */
@media (max-width: 768px) {
  .details-container {
    padding: 40px;
  }

  .details-container h1 {
    font-size: 2rem;
  }

  .details-container p {
    font-size: 1rem;
  }
}

/* Per schermi piccoli (smartphone, 480px) */
@media (max-width: 480px) {
  .video-container {
    height: 60vh; /* Ridurre altezza del video su schermi piccoli */
  }

  .details-container {
    padding: 20px;
  }

  .details-container h1 {
    font-size: 1.8rem;
  }

  .details-container p {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .details-container img {
    margin-top: 15px;
    box-shadow: none; /* Rimuovere l'ombra per semplicità su schermi piccoli */
  }
}
