/* src/pages/AboutPage.css */
.about-page {
  text-align: center;
  padding: 50px;
  height: auto;
  background-color: var(--color-background-dark);
}

.about-content {
  margin-top: 13vh;
}
