/* Contenitore principale */
.title-options-container {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Titolo principale */
.title-options-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--color-text-dark);
  text-align: left;
}

/* Sottotitolo */
.title-options-sub_title {
  font-size: 0.9rem;
  margin-top: 30px;
  text-align: center;
  font-weight: normal; /* Rimuove il grassetto */
  color: var(--color-text-dark);
}

/* Contenuto principale con due colonne */
.title-options-content {
  display: flex;
  gap: 20px;
  width: 100%;
}

/* Colonna sinistra */
.title-options-left {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Elementi della colonna sinistra */
.left-item {
  background-color: var(--color-background-dark);
  border-radius: 15px;
  padding: 15px;
  color: var(--color-muted);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-item-title {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  color: var(--color-muted);
}

.left-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-item-subtitle {
  font-size: 1rem;
  color: var(--color-text-dark);
}

.left-item-description {
  font-size: 0.9rem;
  text-align: right;
}

/* Colonna destra */
.title-options-right {
  flex: 2;
  background-color: var(--color-background-dark);
  border-radius: 15px;
  padding: 20px;
  color: var(--color-text-light);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.right-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--color-text-dark);
}

/* Lista nella colonna destra */
.right-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.right-list-item {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
  gap: 20px;
  font-size: 1rem;
  color: var(--color-text-dark);
}

.list-icon {
  font-size: 1.2rem;
  color: var(--color-muted);
}

.list-text {
  flex: 1;
}

/* Media query per schermi più piccoli (tablet e dispositivi mobili) */
@media (max-width: 768px) {
  .title-options-content {
    flex-direction: column; /* Cambia da due colonne a una colonna */
  }

  .title-options-left {
    flex: 1; /* La colonna sinistra occupa tutta la larghezza */
    margin-bottom: 20px; /* Distanza tra le due sezioni */
  }

  .title-options-right {
    flex: 1; /* La colonna destra occupa tutta la larghezza */
  }
}

/* Media query per schermi molto piccoli (dispositivi mobili) */
@media (max-width: 480px) {
  .title-options-title {
    font-size: 2rem; /* Riduci la dimensione del titolo */
  }

  .left-item-title {
    font-size: 0.85rem; /* Riduci la dimensione del titolo nelle card */
  }

  .left-item-subtitle {
    font-size: 0.9rem; /* Riduci la dimensione del sottotitolo */
  }

  .left-item-description {
    font-size: 0.85rem; /* Riduci la dimensione della descrizione */
  }

  .right-title {
    font-size: 1.3rem; /* Riduci la dimensione del titolo nella colonna destra */
  }

  .right-list-item {
    font-size: 0.9rem; /* Riduci la dimensione del testo nell'elenco */
  }
}
