/* Stile minimale per il monitoraggio in tempo reale */

.service-page {
  min-height: 100vh;
  /* Occupare tutta l'altezza dello schermo */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  font-family: "Arial", sans-serif;
  color: #333;
}

.service-content {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.service-container {
  display: flex;
  flex-direction: row;
  /* Titolo a sinistra, mappa a destra */
  margin-top: 30vh;
  margin-bottom: 20vh;
  width: 80%;
  /* Larghezza massima del contenuto */
  height: 80%;
  /* Altezza massima del contenuto */
  gap: 40px;
  /* Spaziatura tra titolo e ReactFlow */
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}

.service-left {
  flex: 1;
  /* Occupa metà dello spazio disponibile */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  /* Spaziatura tra titolo e descrizione */
  padding: 20px;
  color: #555;
}

.service-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  color: #222;
}

.service-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.service-right {
  flex: 2;
  /* ReactFlow occupa più spazio */
  display: flex;
  height: 50vh;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  /* Assicurati che ReactFlow non esca dai bordi */
}