/* Contenitore principale della pagina */
.contact-page {
  display: flex;
  justify-content: center;
  /* Allinea orizzontalmente al centro */
  align-items: center;
  /* Allinea verticalmente al centro */
  min-height: 100vh;
  /* Imposta l'altezza minima al 100% della finestra */
  background-color: var(--color-background);
  padding: 20px;
  color: var(--color-text);
}

/* Contenuto del modulo di contatto */
.contact-content {
  padding: 150px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0;
}

/* Stile del contenitore principale del form */
.contact {
  width: 100%;
  max-width: 1000px;
  text-align: center;
  color: var(--color-text);
  margin-bottom: 20vh;
}

.contact h1 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 30px;
  font-weight: 600;
}

/* Stile per il titolo della sezione */
.contact-content h2 {
  color: var(--color-muted);
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.2rem;
}

/* Stile per il modulo */
.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: stretch;
}

/* Stile per ogni gruppo di input */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Etichette degli input */
.form-group label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

/* Input e textarea */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  margin-top: 5px;
}

/* Textarea specifico */
.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

/* Pulsante di invio */
.submit-button {
  background-color: #0d6efd;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  grid-column: span 2;
  /* Il pulsante di invio coprirà entrambe le colonne */
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Media query per schermi più piccoli */
@media (max-width: 768px) {
  .contact-form {
    grid-template-columns: 1fr;
    /* Una sola colonna sui dispositivi mobili */
  }

  .submit-button {
    grid-column: span 1;
    /* Pulsante di invio su tutta la larghezza */
  }
}