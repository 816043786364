/* Contenitore principale */
.description-photo-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
}

/* Layout principale */
.description-photo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Modalità chiara */
.description-photo-container.light-mode {
  color: var(--color-text, #000000); /* Default nero */
}

/* Modalità scura */
.description-photo-container.dark-mode {
  color: var(--color-text-dark, #ffffff); /* Default bianco */
}

.description-photo-container.dark-mode h1,
.description-photo-container.dark-mode h2 {
  color: inherit;
}

.description-photo-container.light-mode h1,
.description-photo-container.light-mode h2 {
  color: inherit;
}

/* Contenuto del testo */
.text-content {
  flex: 1;
  text-align: left;
  padding: 20px;
}

.text-content h1,
.text-content h2 {
  margin: 0;
}

.text-content h2 {
  margin-top: 10px;
  font-size: 1.7rem;
  color: inherit;
}

/* Contenuto dell'immagine */
.image-content {
  flex: 1;
  text-align: center;
}

.image-content img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Linea separatrice */
.separator-line {
  width: 100%; /* Copre tutto il contenitore */
  height: 1px;
  background-color: var(--color-border, #d3d3d3); /* Colore della linea */
  margin: 10vh 0;
}

/* --- Responsività per schermi piccoli --- */
@media (max-width: 768px) {
  .description-photo {
    flex-direction: column; /* Disposizione verticale */
    gap: 20px;
  }

  .text-content {
    text-align: left;
  }

  .image-content img {
    max-width: 300px; /* Ridimensiona l'immagine su schermi piccoli */
  }

  .separator-line {
    width: 100%; /* Riduce la larghezza della linea */
    margin: 10vh auto;
  }
}
