/* Navbar styles */
.navbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  backdrop-filter: blur(10px);
  border-radius: 13px;
  padding: 6px 12px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: center;
  transition: background-color 0.3s ease;
  /* Aggiungi la transizione per il cambio di colore */
}

/* Navbar styles for light background */
.navbar-light {
  background: var(--color-navbar);
  color: var(--color-text-dark);
}

/* Navbar styles for dark background */
.navbar-dark {
  background: var(--color-navbar-dark);
  color: var(--color-text-dark);
}

/* Container of navbar items */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Styling for the logo */
.navbar-logo img {
  width: 50px;
  height: auto;
}

.navbar-links li {
  margin-left: 10px;
  display: flex;
  /* Cambiato da inline-block a flex */
  align-items: center;
  /* Allinea verticalmente */
}

/* Generale: stile base per i link */
.navbar-links a {
  color: var(--color-text);
  /* Colore di default per light mode */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  /* Transizione fluida per il cambio colore */
}

/* Stile in modalità light */
.navbar-light .navbar-links a {
  color: var(--color-text);
  /* Testo scuro per light mode */
}

.navbar-light .navbar-links a:hover {
  color: var(--color-primary);
  /* Colore in evidenza */
}

/* Stile in modalità dark */
.navbar-dark .navbar-links a {
  color: var(--color-text-dark);
  /* Testo chiaro per dark mode */
}

.navbar-dark .navbar-links a:hover {
  color: var(--color-primary);
  /* Colore in evidenza */
}

/* Dropdown menu specifico */
.navbar-dark .dropdown-menu a {
  color: var(--color-text-dark);
  /* Testo chiaro per dark mode nel dropdown */
}

.navbar-dark .dropdown-menu {
  background-color: var(--color-navbar-dark);
  /* Testo chiaro per dark mode nel dropdown */
}

.navbar-dark .dropdown-toggle {
  color: var(--color-text-dark);
  /* Testo chiaro per dark mode nel dropdown */
}

.navbar-dark .dropdown-menu a:hover {
  color: var(--color-primary);
  /* Colore in evidenza */
}

.navbar-light .dropdown-menu a {
  color: var(--color-text);
  /* Testo scuro per light mode nel dropdown */
}

.navbar-light .dropdown-menu a:hover {
  color: var(--color-primary);
  /* Colore in evidenza */
}

.navbar-light .dropdown-menu {
  background-color: var(--color-navbar);
  /* Testo chiaro per dark mode nel dropdown */
}

.navbar-links {
  position: absolute;
  /* Cambia da fixed ad absolute */
  top: 100%;
  /* Posiziona il menu appena sotto la navbar */
  right: 24px;
  /* Allinea il menu al lato destro */
  background-color: var(--color-background);
  /* Default background color */
  display: none;
  /* Nascondi il menu di default */
  flex-direction: column;
  gap: 12px;
  /* Spaziatura tra gli elementi */
  padding: 12px 16px;
  /* Spaziatura interna */
  border-radius: 12px;
  /* Angoli stondati */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Leggera ombra */
  z-index: 1000;
  /* Assicura che sia sopra altri elementi */
  transition: all 0.3s ease;
  /* Transizione fluida */
}

.navbar-links.open {
  display: flex;
  /* Mostra il menu quando è aperto */
  width: auto;
  /* Occupare solo lo spazio degli elementi */
  height: auto;
  /* Adattare l'altezza al contenuto */
}

.navbar-dark .navbar-links.open {
  background-color: var(--color-background-dark);
  /* Cambia il colore di sfondo */
}

.navbar-light .navbar-links.open {
  background-color: var(--color-background);
  /* Cambia il colore di sfondo */
}

.navbar-links a {
  color: rgb(0, 0, 0);
  /* Testo leggibile sullo sfondo */
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a:hover {
  color: var(--color-primary);
  /* Colore in evidenza */
}

/* Button styles */
.navbar-btn {
  margin-left: 0px;
  /* Ensure it aligns with other navbar items */
  white-space: nowrap;
}

/* Hamburger menu for mobile screens */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.navbar-dark .hamburger .bar {
  width: 30px;
  height: 3px;
  background-color: var(--color-text-dark);
  transition: 0.3s;
}

.navbar-light .hamburger .bar {
  width: 30px;
  height: 3px;
  background-color: var(--color-text);
  transition: 0.3s;
}

/* Media queries for responsiveness */

/* Su dispositivi con larghezza maggiore di 768px (desktop) */
@media (min-width: 780px) {
  .navbar {
    width: 80%;
    /* Modifica la larghezza per schermi desktop */
  }

  .navbar-logo img {
    width: 35 px;
  }

  .navbar-links {
    display: flex;
    /* Mostra il menu su desktop */
    flex-direction: row;
    /* Disporre i link orizzontalmente */
    gap: 16px;
    /* Spaziatura tra i link */
    position: static;
    /* Rimuove il posizionamento assoluto */
    background-color: transparent;
    /* Rimuove lo sfondo */
    width: auto;
    /* Larghezza adattata al contenuto */
    height: auto;
    /* Altezza adattata al contenuto */
    padding: 0;
    /* Rimuove il padding */
    box-shadow: none;
    /* Rimuove l'ombra */
  }

  .navbar-links a {
    color: var(--color-text);
    font-size: 1rem;
    white-space: nowrap;
  }

  /* Nasconde il menu hamburger su desktop */
  .hamburger {
    display: none;
  }

  /* Mostra il bottone "Richiedi un preventivo" su desktop */
  .navbar-btn {
    display: block;
  }
}

/* Su dispositivi con larghezza minore o uguale a 768px (mobile) */
@media (max-width: 780px) {
  .navbar {
    width: 80%;
  }

  /* Links */
  .navbar-links {
    gap: 16px;
    /* Meno gap tra i link */
    flex-direction: column;
    align-items: left;
    /* Allinea i link a sinistra */
    text-align: left;
  }

  /* Mostra il menu hamburger su schermi piccoli */
  .hamburger {
    display: flex;
  }

  /* Modifica il comportamento del menu su mobile */
  .navbar-links {
    position: fixed;
    top: 80%;
    width: 100%;
    height: 100vh;
    background-color: var(--color-background);
    display: none;
    flex-direction: column;
    align-items: left;
    gap: 12px;
    padding: 16px;
  }

  .navbar-links.open {
    display: flex;
  }

  /* Nasconde il bottone su schermi piccoli */
  .navbar-btn {
    display: none;
  }
}

/* Su dispositivi con larghezza minore o uguale a 480px (extra mobile) */
@media (max-width: 480px) {
  .navbar {
    width: 80%;
  }

  .navbar-logo img {
    width: 30px;
  }

  .navbar-links a {
    font-size: 0.8rem;
  }
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Per lo spazio tra "Servizi" e la freccia */
  padding: 0;
  /* Rimuove padding extra */
  transition: color var(--transition-speed);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 4px);
  /* Distanza dal pulsante "Servizi" */
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--color-navbar-notransparent);
  border-radius: var(--border-radius);
  padding: var(--spacing-small);
  list-style: none;
  display: none;
  /* Nasconde il menu inizialmente */
  z-index: 1000;
}

.dropdown-toggle:hover {
  color: var(--color-primary);
}

.dropdown-menu.open .dropdown-toggle {
  font-size: 0.8rem;
}

.dropdown-icon {
  transition: transform var(--transition-speed);
  font-size: 0.4rem;
}

.dropdown-icon.open {
  transform: rotate(180deg);
  /* Ruota la freccia verso l'alto */
}

/* Dropdown menu styles */
.dropdown-menu li {
  margin: 0;
  border-radius: 10px;
  gap: 10px;
}

/* Mostra il dropdown quando lo stato è attivo */
.dropdown:hover .dropdown-menu,
.dropdown .dropdown-menu.open {
  display: block;
}

/* Icone nel menu dropdown */
.dropdown-menu a {
  display: flex;
  align-items: center;
  /* Allinea l'icona e il testo */
  padding: var(--spacing-small);
  color: var(--color-text);
  /* Colore del testo di default */
  text-decoration: none;
  transition: background-color var(--transition-speed),
    color var(--transition-speed);
}

.dropdown-menu a svg {
  margin-right: 8px;
  /* Distanza tra l'icona e il testo */
  font-size: 1rem;
  /* Dimensione delle icone */
  transition: fill 0.3s ease;
  /* Transizione per il cambiamento di colore dell'icona */
}

.dropdown-menu a:hover {
  color: var(--color-text);
  /* Mantieni il testo di default, ma puoi modificarlo se lo desideri */
}

/* Dropdown menu icons - Light mode */
.navbar-light .dropdown-menu a svg {
  fill: var(--color-text);
  /* Default: colore di bordo */
}

.navbar-light .dropdown-menu a:hover svg {
  fill: var(--color-primary);
  /* Hover: colore testo */
}

/* Dropdown menu icons - Dark mode */
.navbar-dark .dropdown-menu a svg {
  fill: var(--color-text-dark);
  /* Default: colore testo scuro */
}

.navbar-dark .dropdown-menu a:hover svg {
  fill: var(--color-primary);
  /* Hover: colore di bordo */
}
