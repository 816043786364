@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* Variabili globali per colori, tipografia e spaziature */
:root {
  --font-family-sans: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
  --font-family-mono: "Fira Code", "Courier New", Courier, monospace;
  --color-primary: #0d6efd; /* Blu primario */
  --color-secondary: #6c757d; /* Grigio secondario */
  --color-background: #ffffff; /* Sfondo chiaro */
  --color-background-dark: linear-gradient(
    135deg,
    rgba(45, 45, 45, 0.8),
    rgba(68, 68, 68, 0.8)
  );
  --color-navbar: rgba(255, 255, 255, 0.5); /* Sfondo chiaro */
  --color-navbar-notransparent: rgb(163, 163, 163); /* Sfondo chiaro */
  --color-background-dirty-dark: rgb(19, 19, 21);
  --color-navbar-dark: rgba(32, 32, 32, 0.4); /* Sfondo chiaro */
  --color-border: rgba(165, 165, 165, 0.5); /* Sfondo chiaro */
  --color-text: #000000; /* Testo principale */
  --color-text-dark: #ffffff; /* Testo chiaro */
  --color-muted: #9b9c9d; /* Testo secondario */
  --color-accent: #198754; /* Verde per accenti */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;
  --border-radius: 8px;
  --transition-speed: 0.3s;
}

html {
  scroll-behavior: smooth; /* Aggiungi scroll fluido */
}
/* Reset di base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family-sans);
  font-size: 16px;
  color: var(--color-text);
  background-color: var(--color-background-dark);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  transition: all 0.3s ease-out; /* Imposta una transizione generale per rendere l'esperienza di navigazione più fluida */
}

body {
  overscroll-behavior-y: none;
  background: var(--color-background-dark);
  font-family: "Inter var", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: black;
}

a {
  text-decoration: none;
  color: var(--color-primary);
  transition: color var(--transition-speed);
}

a:hover {
  color: var(--color-accent);
}

/* Codice (monospace font) */
code {
  font-family: var(--font-family-mono);
  background-color: rgba(0, 0, 0, 0.05);
  padding: var(--spacing-small);
  border-radius: var(--border-radius);
}

/* Titoli */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: var(--spacing-medium);
  color: var(--color-text-dark);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.2rem;
  color: var(--color-muted);
  font-weight: normal;
}

/* Paragrafi */
p {
  margin-bottom: var(--spacing-medium);
}

/* Sezioni */
.section {
  padding: var(--spacing-large) var(--spacing-medium);
}

.section-title {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: var(--spacing-medium);
}

/* Media Query */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }
}
