/* Contenitore principale */
.image-section-container {
  display: flex;
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
  padding: 20px; /* Aggiunge padding per evitare che tocchi i bordi */
  width: 100%;
  height: auto;
}

/* Stile per la sezione immagine */
.image-section-photo {
  width: 80%; /* Usa l'80% della larghezza disponibile */
  max-width: 1200px; /* Limita la larghezza massima */
  text-align: center;
  height: auto;
}

/* Stile immagine */
.image-section-photo img {
  width: 100%;
  height: auto;
  border-radius: 12px; /* Arrotonda gli angoli dell'immagine */
  object-fit: cover; /* Adatta l'immagine senza deformarla */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Aggiunge un'ombra delicata */
}

/* Regole per schermi piccoli */
@media (max-width: 768px) {
  .image-section-photo {
    width: 95%; /* Usa quasi tutta la larghezza dello schermo */
  }

  .image-section-photo img {
    border-radius: 8px; /* Angoli leggermente meno arrotondati */
  }
}

/* Regole per schermi molto piccoli */
@media (max-width: 480px) {
  .image-section-photo {
    width: 100%; /* Usa tutta la larghezza disponibile */
  }

  .image-section-container {
    padding: 10px; /* Riduci il padding sui lati */
  }

  .image-section-photo img {
    border-radius: 6px; /* Riduci ulteriormente il raggio per mantenere proporzioni */
  }
}
