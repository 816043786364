/* Contenitore principale del footer */
.footer-container {
  width: 95%;
  margin: 0 auto;
  margin-top: 5vh;
  border-radius: 20px;
  background-color: var(--color-background);
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  height: 60vh;
  gap: 20px;
}

/* Prima riga */
.footer-top {
  padding: 3vh 3vh;
  text-align: center;
}

.footer-intro h2 {
  font-size: 2.3rem;
  color: var(--color-text);
  margin-bottom: 10px;
}

.footer-intro p {
  margin-bottom: 15px;
  font-size: 1rem;
  text-align: center;
  color: var(--color-muted);
}

.footer-intro button {
  background-color: var(--color-primary);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer-intro button:hover {
  background-color: var(--color-background-dark);
  color: var(--color-primary);
}

/* Seconda riga */
.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  text-align: center;
}

.footer-links h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: var(--color-text);
}

.footer-links a {
  color: var(--color-muted);
  text-decoration: none;
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: block;
}

.footer-links a:hover {
  color: var(--color-primary);
}

/* Componente sotto il footer */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 10px;
  margin-top: 20px;
}

.footer-logo img {
  width: 50px;
}

.footer-copyright {
  flex: 1;
  text-align: center;
  font-size: 0.9rem;
  color: var(--color-muted);
}

.footer-social a {
  margin: 0 10px;
  display: inline-block;
}

.footer-social img {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.footer-social img:hover {
  transform: scale(1.1);
}
