/* Main page container */
.blog-page {
  display: flex;
  justify-content: center; /* Align horizontally at the center */
  align-items: center; /* Align vertically at the center */
  min-height: 100vh; /* Set minimum height to 100% of the viewport */
  background-color: var(--color-background);
  padding: 20px;
  color: var(--color-text);
}

/* Content section */
.blog-content {
  border-radius: 20px;
  width: 100%;
  backdrop-filter: blur(15px);
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two-column grid */
  gap: 20px; /* Spacing between columns and rows */
  padding: 20px;
}

/* Blog card container */
.blog-card-link {
  display: block; /* Ensures that the link occupies the entire card */
  text-decoration: none; /* Removes default underline of links */
  color: inherit; /* Inherits text color */
}

.blog-card {
  position: relative;
  width: 100%;
  height: 250px; /* Fixed height for each card */
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: scale(1.05); /* Scale effect on hover */
}

.blog-card-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  background-color: rgba(
    173,
    173,
    173,
    0.1
  ); /* Semi-transparent background for better readability */
  padding: 10px;
  border-radius: 5px;
}

.blog-card-content h3 {
  margin: 0;
  font-size: 1.2rem;
}

.blog-card-content p {
  font-size: 1rem;
  margin-top: 5px;
}

.blog-card:hover {
  transform: translateY(-5px); /* Slightly lift the card on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.9);
}

.blog-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5%; /* Height of the blurred area */
  background: rgba(0, 0, 0, 0.1); /* Semi-transparent dark background */
  backdrop-filter: blur(30px); /* Blur effect */
  z-index: 1; /* Position above the background but below the content */
}

.blog-card-content {
  position: absolute;
  bottom: 10px; /* Margin from the bottom */
  left: 10px; /* Margin from the left */
  color: white; /* White text for contrast */
  z-index: 2; /* Above the blurred area */
}

/* Main content container for the blog */
.blog-main {
  width: 100%;
  max-width: 1400px;
  text-align: center;
  color: var(--color-text);
  margin-bottom: 20vh;
}

.blog-main h1 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 30px;
  font-weight: 600;
}

/* Title for the blog section */
.blog-content h2 {
  color: var(--color-muted);
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.2rem;
}

.blog-card-content h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.blog-card-content p {
  margin: 5px 0 0; /* Margin on top and none below */
  font-size: 0.9rem;
}

.blog-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(125, 125, 125, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-size: cover;
  background-position: center;
  height: 500px;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.blog-card:hover::after {
  height: 25%; /* Increases the blurred area on hover */
}

.blog-card-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  z-index: 2;
  overflow: hidden;
  max-height: 40px; /* Initially shows only the title */
  transition: max-height 0.3s ease;
}

.blog-card:hover .blog-card-content {
  max-height: 150px; /* Expands to show description on hover */
}

.blog-card-content h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.blog-card-content p {
  margin: 5px 0 0;
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.blog-card:hover .blog-card-content p {
  opacity: 1;
}
