/* Contenitore principale */
.dynamic-text-container {
  position: relative;
  padding: 2px; /* Margine interno per allontanare il testo dai bordi */
}

/* Stile per ogni lettera animata */
.wave-letter {
  display: inline-block;
  animation: waveAnimation 2s infinite ease-in-out;
  color: #000; /* Colore iniziale */
}

/* Animazione */
@keyframes waveAnimation {
  0% {
    color: #ff5733;
  }
  25% {
    color: #33ff57;
  }
  50% {
    color: #3357ff;
  }
  75% {
    color: #ff33a1;
  }
  100% {
    color: #f1c40f;
  }
}
