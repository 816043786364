/* Contenitore principale per le opzioni */
.options-container {
  text-align: center;
  padding: 20px;
  /* max-width: 1000px; */
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

/* Titolo della sezione delle opzioni */
.options-container h2 {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 40px;
  font-weight: 600;
}

/* Griglia di opzioni */
.options-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* Spazio tra le opzioni */
  justify-items: center;
  /* Centra ogni opzione orizzontalmente */
}

/* Animazione per l'entrata delle opzioni */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
    /* Partono leggermente sopra */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* Tornano alla posizione originale */
  }
}

/* Ogni singola opzione */
.option {
  background-color: rgba(112, 111, 111, 0.1);
  padding: 30px;
  /* Aumentato il padding per renderle più grandi */
  border-radius: 20px;
  /* Aumento degli angoli arrotondati */
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  /* Aumento della dimensione della shadow */
  text-align: center;
  width: 100%;
  max-width: 350px;
  /* Aumento della larghezza massima */
  min-height: 250px;
  /* Altezza minima */
  height: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: slideIn 0.6s ease-out;
  animation-play-state: paused;
  /* L'animazione è in pausa di default */
  opacity: 0;
  /* Rende l'opzione invisibile inizialmente */
}

.option.visible {
  animation-play-state: running;
  /* Avvia l'animazione */
  opacity: 1;
  /* Rende l'opzione visibile */
}

/* Titolo dell'opzione */
.option h3 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 15px;
  font-weight: bold;
  /* Rende il testo grassetto */
}

/* Descrizione dell'opzione */
.option p {
  font-size: 1rem;
  color: var(--color-muted);
  margin-bottom: 20px;
}

/* Pulsante sotto ogni opzione */
.option button {
  background-color: var(--color-primary);
  color: white;
  padding: 12px 24px;
  /* Aumentato il padding del pulsante */
  border: none;
  border-radius: 12px;
  /* Rende il pulsante più arrotondato */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.option a {
  background-color: var(--color-primary);
  color: white;
  padding: 12px 24px;
  /* Aumentato il padding del pulsante */
  border: none;
  border-radius: 12px;
  /* Rende il pulsante più arrotondato */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.option button:hover {
  background-color: var(--color-primary-dark);
}



.option a:hover {
  background-color: var(--color-primary-dark);
}

/* Media query per schermi medi */
@media (max-width: 1024px) {
  .options-grid {
    grid-template-columns: repeat(2, 1fr);
    /* Due colonne su schermi medi */
  }
}

/* Media query per dispositivi piccoli */
@media (max-width: 768px) {
  .options-grid {
    grid-template-columns: 1fr;
    /* Una sola colonna su dispositivi piccoli */
  }
}