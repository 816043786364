/* Contenitore principale */
.title-options2-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 1600px;
  margin-bottom: 20vh;
}

/* Titolo principale */
.title-options2-title {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10vh;
  color: var(--color-text);
}

/* Sottotitolo */
.title-options2-subtitle {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 30px;
  color: var(--color-text);
}

/* Griglia di contenuti */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 colonne per la griglia */
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}

/* Elementi della griglia con effetto vetro smerigliato */
.grid-item {
  background-color: var(--color-background-dark);
  /* Colore semitrasparente */
  padding: 20px;
  width: 100%;
  min-height: 300px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 0px;

  /* Effetto vetro smerigliato */
  backdrop-filter: blur(30px);
  /* Aggiungi sfocatura dietro l'elemento */
  -webkit-backdrop-filter: blur(10px);
  /* Supporto per Safari */

  /* Aggiunta del colore di sfondo e bordi */
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* Leggero bordo traslucido */

  transition: all 0.3s ease;
  /* Transizione per il cambiamento di colore */
}

/* Cambia il colore di sfondo quando l'utente passa sopra */
.grid-item:hover {
  background-color: var(--color-background-dirty-dark);
  /* Cambia leggermente il colore di sfondo */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  /* Aumenta l'ombra per effetto hover */
  transform: scale(1.05);
  /* Aumenta leggermente la dimensione dell'elemento */
}

/* Icona e Titolo: disposizione orizzontale */
.grid-item-title-container {
  display: flex;
  margin-bottom: 10px;
  height: 35%;
  justify-content: center;
}

/* Icona */
.grid-item-icon {
  margin-right: 15px;
  font-size: 2rem;
}

/* Titolo dell'elemento */
.grid-item-title {
  font-size: 1.8rem;
  font-weight: 500;

}

/* Descrizione dell'elemento - Centrata */
.grid-item-description {
  font-size: 1rem;
  color: var(--color-muted);
  margin-top: 10px;
  text-align: center;
}

/* Responsività: gestione per schermi più piccoli */

/* Quando la larghezza è inferiore a 1200px (tablet e schermi medi) */
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    /* 2 colonne per schermi medi */
  }

  .title-options2-container {
    width: 100%;
  }

  .grid-container {
    width: 100%;
  }
}

/* Quando la larghezza è inferiore a 768px (smartphone e schermi piccoli) */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    /* Una colonna per schermi molto piccoli */
  }
}