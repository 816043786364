.block-wrapper {
  margin-bottom: 20px; /* Modifica il valore per aumentare o ridurre lo spazio */
}

.block-wrapper:last-child {
  margin-bottom: 0; /* Rimuove lo spazio extra per l'ultimo blocco */
}

/* BlogRenderer.css */

/* Immagini */
.article-image {
  width: 100%; /* Rende l'immagine responsive */
  max-width: 800px; /* Limita la larghezza massima per evitare che sia troppo grande */
  height: auto; /* Mantiene il rapporto d'aspetto */
  border-radius: 8px; /* Aggiunge angoli arrotondati */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Aggiunge un'ombra leggera per effetto tridimensionale */
  margin: 20px auto; /* Centra e aggiunge spazio sopra/sotto */
  display: block; /* Centra l'immagine */
}

/* Blocchi di codice */
pre {
  background-color: #1e1e1e; /* Sfondo scuro per contrasto */
  color: #dcdcdc; /* Colore del testo chiaro */
  padding: 15px; /* Spaziatura interna */
  border-radius: 8px; /* Angoli arrotondati */
  overflow-x: auto; /* Scroll orizzontale per righe lunghe */
  font-family: "Courier New", Courier, monospace; /* Font monospaziato */
  margin: 20px 0; /* Spaziatura sopra/sotto */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Ombra */
}

code {
  color: #c586c0; /* Colore del codice */
  font-size: 0.9rem; /* Dimensione più piccola per codice inline */
}

/* Liste */
ul {
  padding-left: 40px; /* Indentazione per le liste */
  margin: 20px 0; /* Spaziatura sopra/sotto */
  list-style-type: disc; /* Tipo di lista (cerchietti) */
}

ul li {
  font-size: 1rem; /* Dimensione del testo */
  line-height: 1.6; /* Spaziatura tra righe */
  color: #333; /* Colore leggibile */
  margin-bottom: 10px; /* Spaziatura tra elementi */
}

/* Testo */
p {
  font-size: 1.1rem; /* Dimensione leggibile */
  line-height: 1.8; /* Spaziatura tra righe per leggibilità */
  color: #444; /* Colore neutro e professionale */
  margin: 20px 0; /* Spaziatura sopra/sotto */
  text-align: justify; /* Giustifica il testo per un aspetto professionale */
}

/* Media Queries per dispositivi mobili */
@media (max-width: 768px) {
  .article-image {
    max-width: 100%; /* Immagini occupano tutta la larghezza disponibile */
  }

  pre {
    font-size: 0.9rem; /* Testo del codice leggermente più piccolo su schermi piccoli */
  }

  ul {
    padding-left: 20px; /* Meno indentazione per liste */
  }

  p {
    font-size: 1rem; /* Riduce la dimensione del font per adattarsi agli schermi più piccoli */
  }
}
