/* Contenitore principale */
.description-photolong-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Modalità chiara */
.description-photolong-container.light-mode {
  color: var(--color-text); /* Default nero */
}

/* Modalità scura */
.description-photolong-container.dark-mode {
  color: var(--color-text-dark); /* Default bianco */
}

/* Sezione principale (titolo e descrizione) */
.description-photolong {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Titolo a sinistra */
.title-section {
  flex: 1; /* Occupa 25% dello spazio */
  max-width: 25%; /* Mantiene il limite */
  text-align: left;
  padding-right: 20px; /* Spazio tra titolo e descrizione */
  margin-left: 10px;
}

.title-section h1 {
  margin: 0;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--color-text-dark);
}

/* Descrizione a destra */
.description-section {
  flex: 3; /* Occupa 75% dello spazio */
  max-width: 75%;
  text-align: left;
  padding: 10px; /* Aggiungi padding per evitare che il testo tocchi i bordi */
  word-wrap: break-word; /* Le parole lunghe si spezzeranno */
  overflow-wrap: break-word; /* Se le parole sono troppo lunghe, si spezzeranno */
  white-space: normal; /* Impedisce che il testo rimanga tutto su una riga */
}

/* Per garantire che il padding venga applicato correttamente */
.description-section h6 {
  font-size: 0.9rem;
  font-weight: 400; /* Imposta un peso normale per il font */
  margin: 0;
  line-height: 1.5;
  padding: 0 10px; /* Padding laterale per evitare che il testo tocchi i bordi */
}

/* Immagine larga 100% */
.image-section {
  width: 100%;
  margin: 50px 0;
  text-align: center;
}

.image-section img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

/* Responsività per schermi piccoli */
@media (max-width: 768px) {
  .description-photolong {
    flex-direction: column; /* Disposizione verticale */
    align-items: flex-start; /* Allinea il testo a sinistra */
  }

  .title-section {
    max-width: 100%; /* Titolo prende tutta la larghezza */
    margin-bottom: 10px; /* Margine tra titolo e descrizione */
    text-align: left; /* Assicura che il titolo sia a sinistra */
  }

  .description-section {
    max-width: 100%; /* Descrizione prende tutta la larghezza */
    text-align: left; /* Allinea il testo a sinistra */
  }

  .image-section {
    text-align: center; /* Centra l'immagine */
  }

  .image-section img {
    width: 100%; /* Immagine larga 100% */
    border-radius: 0; /* Rimuove bordi arrotondati su schermi piccoli */
  }
}
