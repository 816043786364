/* BlogDetail.css */
.profile-image {
  width: 100%; /* L'immagine occupa tutta la larghezza del contenitore */
  height: 100vh; /* Altezza uguale alla viewport */
  object-fit: cover; /* Mantiene le proporzioni dell'immagine e la adatta */
}

/* Assicurati che la pagina sia centrata e abbia un layout responsive */
.blog-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-background);
  width: 100%;
  height: auto;
}

.blog-detail {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20vh;
}

.article-image {
  width: 100%;
  height: 100vh;
  object-fit: cover; /* L'immagine copre tutta l'altezza della finestra */
}

.article-content {
  width: 100%;
  padding: 80px;
}

.blog-detail-page h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-text);
  margin-bottom: 20px;
  margin-top: 20px;
}

.blog-detail h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 20px;
}

.blog-detail p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

/* Aggiungi l'effetto di transizione per il contenuto che appare dopo l'immagine */
.blog-detail-page:hover .content-container {
  transform: translateY(
    0
  ); /* Il contenuto si sposterà nella posizione originale durante il passaggio del mouse */
}

/* Media Queries per dispositivi mobili */
@media (max-width: 768px) {
  .content-container {
    width: 90%;
    padding: 20px;
  }
}

/* Media Queries per dispositivi mobili */
@media (max-width: 768px) {
  .profile-image {
    height: auto; /* Lascia che l'immagine si ridimensioni automaticamente */
    max-height: 100vh; /* Mantieni un limite massimo di altezza */
  }

  .article-content {
    padding: 20px; /* Riduci il padding sui dispositivi più piccoli */
  }
}
