/* Stile del contenitore principale del form */
.cs {
  width: 100%;
  max-width: 1400px;
  text-align: center;
  color: var(--color-text);
  margin-bottom: 20vh;
}

.cs h1 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 30px;
  font-weight: 600;
}
/* Contenitore principale della pagina */
.cs-page {
  display: flex;
  justify-content: center; /* Allinea orizzontalmente al centro */
  align-items: center; /* Allinea verticalmente al centro */
  min-height: 100vh; /* Imposta l'altezza minima al 100% della finestra */
  background-color: var(--color-background);
  padding: 20px;
  color: var(--color-text);
}
/* Contenitore principale della pagina */
.cs-page {
  display: flex;
  justify-content: center; /* Allinea orizzontalmente al centro */
  align-items: center; /* Allinea verticalmente al centro */
  min-height: 100vh; /* Imposta l'altezza minima al 100% della finestra */
  background-color: var(--color-background);
  padding: 20px;
  color: var(--color-text);
}

/* Contenuto del modulo di contatto */
.cs-content {
  border-radius: 20px;
  width: 100%;
  backdrop-filter: blur(15px);
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Griglia a due colonne */
  gap: 20px; /* Spaziatura tra le colonne e le righe */
  padding: 20px;
}

.cs-card {
  position: relative; /* Necessario per posizionare gli elementi interni */
  border-radius: 10px;
  overflow: hidden; /* Taglia i contenuti che eccedono i bordi */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-size: cover; /* L'immagine di sfondo copre tutta la card */
  background-position: center; /* Centra l'immagine di sfondo */
  height: 500px; /* Altezza delle card */
}

.cs-card:hover {
  transform: translateY(-10px); /* Solleva leggermente la card */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.cs-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%; /* Altezza dell'area blurrata */
  backdrop-filter: blur(30px); /* Effetto blur */
  z-index: 1; /* Posiziona sopra lo sfondo ma sotto il contenuto */
}
