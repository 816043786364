.mp-maps-container {
  display: flex;
  justify-content: center;
  /* Centra le mappe */
  width: 100%;
  gap: 20px;
  /* Distanza tra le due mappe */
}

.mp-info-container {
  width: 50%;
  /* Metà dello spazio disponibile per ogni mappa */
}

.concept-map {
  position: relative;
  width: auto;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin: 0 auto;
}

.center-horiz {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coppia-1 {
  position: absolute;
  left: 33%;
  transform: translate(-50%, -50%);
}

.coppia-2 {
  position: absolute;
  left: 66%;
  transform: translate(-50%, -50%);
}

.node {
  position: absolute;
  width: 30%;
  height: auto;
  background-color: #0078d7;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  min-width: 150px;
}

.connections {
  position: absolute;
  width: 100%;
  height: 100%;
}

.connections line {
  stroke: #333;
  stroke-width: 2;
}